import { imgUrl } from '../../templates/consts';
import '../../stylesheets/Reading.css';
import { useSession } from '../../hooks/useSessionDev';
import { useEffect, useState } from 'react';
import moment from 'moment';

interface Props {
    val: any;
    alert?: any;
    coil: boolean;
}

const DiffReading: React.FC<Props> = ({ val, alert, coil }) => {

    const [sessionData] = useSession();

    const assessStatus = () => {
        let alertClass = 'prim';
        if (!val.hasOwnProperty('days')) {
            alertClass = 'inactive';
        } else if ((val.days <= 25 && val.days > 10) || alert.dpr.bad) {
            alertClass = 'cancel';
        } else if ((val.days <= 10 && val.days > -1) || alert.dpr.crit || val.dpr > 120) {
            alertClass = 'crit';
        }
        return alertClass;
        // return !coil ? alertClass : 'prim';
    }
    const assessMaintDate = () => {
        if (!val.hasOwnProperty('days')) return '-';
        const days = !coil ? val.days : 270;
        if (days === 0) return 'IMMINENT';
        const now = Math.floor(Date.now() / 1000);
        let newDate = moment((now + days * 86400) * 1000).format('MMM-DD, YYYY');
        if (coil) newDate += '+'
        return newDate;
    }
    return (
        <div
            className={`reading-node ${assessStatus()}-reading-node diff-reading-node`}
            style={{
                height: window.innerHeight >= 400 && window.innerHeight <= 880 && window.innerWidth > 767 ? '120px' : ''
            }}>
            <div className='reading-header' style={{ height: '50%' }}>
                <img style={{ height: '40px' }} src={`${imgUrl}/${!coil ? 'filterOfficial' : 'coilFlow'}.png`} />
                <div className='diff-maint'>
                    <h5 style={{ color: 'var(--white)' }}>Next Maintenance</h5>
                    <h6 style={{ color: `var(--${assessStatus()})` }}>{assessMaintDate()}</h6>
                </div>
            </div>
            <div className='reading-body diff-body'>
                <div className='diff-reading' style={{ width: '49%' }}>
                    <img src={`${imgUrl}/pressureGauge.png`} />
                    <h2 style={{ color: `var(--${assessStatus()})`, textAlign: 'center', lineHeight: '18px' }}>
                        {!val.dpr && val.dpr.toString() !== '0' ? '-' : (sessionData.iwg ? (val.dpr * 0.0040147).toFixed(3) : val.dpr)}
                        <br />
                        <span style={{ fontSize: '.7em' }}>{sessionData.iwg ? 'iwg' : 'Pa'}</span>
                    </h2>
                </div>
                <div style={{ height: '80%', width: '2px', background: 'var(--white)' }}></div>
                <div className='diff-reading' style={{ width: '49%' }}>
                    <img src={`${imgUrl}/calendar.png`} />
                    <h2 style={{ color: `var(--${assessStatus()})`, textAlign: 'center', lineHeight: '18px' }}>
                        {!coil ? (val.days ? val.days : '-') : '>270'}
                        {/* {val.days ? (!coil ? val.days : '>270') : '-'} */}
                        <br />
                        <span style={{ fontSize: '.7em' }}>days</span>
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default DiffReading;