import { useContext, useState } from "react";
import { measSet } from "../templates/consts";
import { useSession } from "./useSessionDev";
import cToF from 'celsius-to-fahrenheit';
import { AppContext } from "../context/AppContext";
import { useRest } from "./useRest";

export const useTrend = (entType: string, ent: any) => {

    const { appData, setAppData } = useContext(AppContext);

    const [sessionData] = useSession();
    const [req] = useRest();
    
    const [trendData, setTrendData] = useState<any>(null);

    const initErr = (trendsErr: any) => {
        console.error({ trendsErr });
        const err = trendsErr as any;
        setAppData({
        ...appData,
        helper: {
            type: 'error',
            title: 'There was an error retrieving Trend Data',
            content: err.message
        }
        })
    }

    const getDeviceTrends = async (interval: number, tms: number | boolean, dir: string | null, did?: number) => {
        let queryString = tms || interval ? '?' : '';
        if (interval) queryString += `interval=${interval}`
        if (tms) queryString += `&pivotTms=${tms}`;
        if (dir) queryString += `&dir=${dir}`
        try {
          const trends = await req(`trend/${did ?? ent.did}${queryString}`, 'get');
            return trends;
        } catch (getDeviceTrendsErr) {
            initErr(getDeviceTrendsErr);
            return [];
        }
    }

  const setDataNode = (rdg: any, data: any) => {
    const node = data.reduce((rdgSet: any[], pkt: any) => {
          if (!['days'].includes(rdg)) {
              if (pkt.data && pkt.data[rdg]) {
                if (['co2', 'voc'].includes(rdg) && parseInt(pkt.tms) <= 1698548400) {
                  const val = rdg === 'co2' ? parseInt(pkt.data[rdg].avg) : parseFloat(pkt.data[rdg].avg);
                  const adjusted = rdg === 'co2' ? Math.round(val * .76) : parseFloat((val * .35).toFixed(3))
                  rdgSet.push(adjusted);
                } else if (rdg === 'voc' && parseInt(pkt.tms) <= 1698548400) {
                  rdgSet.push((parseFloat(pkt.data[rdg]) * .35).toFixed(3))
                } else if (sessionData.imperial && rdg === 'htp') {
                  rdgSet.push(Math.floor(cToF(parseInt(pkt.data.htp.avg))));
                } else if (rdg === 'dpr' && sessionData.iwg) {
                  let iwgAdjusted = (pkt.data[rdg].avg * .0040147);
                  if (iwgAdjusted < 0) iwgAdjusted = 0
                  rdgSet.push(parseFloat(iwgAdjusted.toFixed(3)))
                } else {
                  let avg = pkt.data[rdg].avg
                  if (avg < 0) avg = 0
                  rdgSet.push(rdg === 'voc' ? parseFloat(avg) : parseInt(avg));
                }
              }
              if (pkt.bat && rdg === 'bat') rdgSet.push(parseInt(pkt.bat));
            }
            return rdgSet;
        }, []);
        return node;
    }

    const setRdgAxis = (prop: string, hasDelta:boolean) => {
        switch (prop) {
            case 'iaq': return 0;
            case 'co2': return 1;
            case 'voc': return 2;
            case 'htp': return !hasDelta ? 3 : 2;
            case 'hhm': return !hasDelta ? 3 : 2;
            case 'bat': return hasDelta ? 2 : 3;
            case 'dpr': return 0;
        }
      }

    const setRdgColor = (prop: string, propName:string) => {
        let color = '';
        switch (prop) {
            case 'iaq': color = 'crit';
            break;
            case 'co2': color = 'cancel';
            break;
            case 'voc': color = 'prim';
            break;
            case 'htp': color = 'breezi-blue';
            break;
            case 'hhm': color = 'tri';
                break;
            case 'dpr': color = 'crit';
              break;
          case 'bat':
              color = 'white';
              break;
            
            default: color = 'white';
        }
        return `var(--${color})`;
    }

    const setAltColor = (name: string) => {
        name = name.toLowerCase();
        if (name.includes('temperature') || name.includes('temp')) return 'blue';
        if (name.includes('humidity')) return 'purple';
        if (name.includes('battery')) return 'lightgray';
        if (name.includes('pressure') || name.includes('dp')) return 'orange';
    }

    const setPropName = (rdg: string) => {
        if (rdg === 'dpr') return 'Pressure Differential'
        const mObj = measSet.find((ms: any) => ms.prop === rdg);
        return mObj ? mObj.title : '';
    }

    const genSeries = (data: any, initGranularUpdate: (index: number, data: any) => void, secondSet:boolean, interval?:number) => {
        let props = ['htp', 'hhm', 'dpr'];
        if (!secondSet) {
            props = props.concat('iaq', 'co2', 'voc')
        }
      // if (sessionData.email === 'support@breezi.io' && interval !== 86400) {
      //     props.push('bat');
      //   }
        const series = props.map((m: any) => ({
            name: setPropName(m),
            data: setDataNode(m, data),
            yAxis: setRdgAxis(m, secondSet),
            lineWidth: 2,
            color: setRdgColor(m, setPropName(m)),
            point: {
              style: {
                cursor: 'pointer'
              },
              events: {
                click() {
                  const point = this as any;
                  const pointIndex = point.index;
                  initGranularUpdate(pointIndex, data);
                }
              }
            }
        }));
        return series;
    }

    const addDeltaTemp = (series: any, initGranularUpdate:(index: number, data: any) => void) => {
        const temps = series.filter((s: any) => s.name.toLowerCase().includes('temperature') || s.name.toLowerCase().includes('temp'));
        const data = temps[0].data.reduce((allDeltas: any[], temp: number, tempIndex: number) => {
            const greaterSet = temp >= temps[1].data[tempIndex] ? 0 : 1;
            const lesserSet = greaterSet === 1 ? 0 : 1;
            const val = temps[greaterSet].data[tempIndex] - temps[lesserSet].data[tempIndex]
            allDeltas.push(val);
            return allDeltas;
        }, []);
        const name = 'Delta Temp';
        const yAxis = 1;
        const lineWidth = 2;
        const color = 'var(--prim)';
        const point = {
            style: {
              cursor: 'pointer'
            },
            events: {
              click() {
                const point = this as any;
                const pointIndex = point.index;
                initGranularUpdate(pointIndex, data);
              }
            }
          }
        const deltaSet = { name, yAxis, lineWidth, color, data, point };
        series.splice(3, 0, deltaSet);
        return series;
    }
  
    const constructCustomAxis = (data: any) => {
      const max = Math.max(...data);
      return () => {
        let counter = 0;
        const int = max / 8;
        let ticks = [0];
        while (ticks.length < 8) {
          ticks.push(counter + int)
          counter += int;
        }
      }
    }

  const deltaYAxis = (set1: any[], set2: any[], set3: any[]) => {
      let max1 = Math.max(...set1);
      if (sessionData.iwg) max1 = max1 * .0040147
      let max2 = Math.max(...set2);
      let max3 = Math.max(...set3);
      max1 += Math.round(max1 / 5);
      max2 += Math.round(max2 / 5);
      max3 += Math.round(max3/5);
        return [
          {
            title: {
              text: 'Pressure Differential',
              style: {
                color: 'var(--crit)'
              }
            },
            opposite: false,
            labels: {
              style: {
                color: 'var(--crit)',
                fontFamily: 'Comfortaa'
              }
          },
          min: 0,
          max: Math.round(max1),
          tickPositioner() {
            let counter = 0;
            const int = Math.round(max1 / 7);
            let ticks = [0];
            while (ticks.length < 8) {
              ticks.push(counter + int)
              counter += int;
            }
            return ticks;
          }
          },
          {
            title: {
              text: 'Delta Temperature',
              style: {
                color: 'var(--prim)'
              }
            },
            opposite: true,
            labels: {
              style: {
                color: 'var(--prim)',
                fontFamily: 'Comfortaa'
              }
            },
            min: 0,
            // max: Math.round(max2),
            tickPositioner() {
              let counter = 0;
              const int = Math.round(max2 / 7);
              let ticks = [0];
              while (ticks.length < 8) {
                ticks.push(counter + int)
                counter += int;
              }
              return ticks
            }
          },
          {
            title: {
              text: 'Temp/Hum',
              style: {
                color: 'var(--tri)'
              }
            },
            opposite: true,
            labels: {
              style: {
                color: 'var(--tri)',
                fontFamily: 'Comfortaa',
              },
            },
            min: 0,
            // max: Math.round(max3),
            tickPositioner() {
              let counter = 0;
              const int = Math.round(max3 / 7);
              let ticks = [0];
              while (ticks.length < 8) {
                ticks.push(counter + int)
                counter += int;
              }
              return ticks
            }
          }
        ]
    }

    const setBands = (series: any) => {
        const dprSer = series.filter((s:any) => s.name.toLowerCase().includes('pressure') || s.name.toLowerCase().includes('dp'));
        const hvacOnIndicies = dprSer[0].data.reduce((onBands: any[], entry: number, entryIndex: number) => {
          entry = !sessionData.iwg ? entry : entry * 248.8;
          let affiliate = dprSer[1].data[entryIndex];
          affiliate = !sessionData.iwg ? affiliate : affiliate * 248.8;
          if (entry >= 20 || affiliate >= 20) onBands.push(entryIndex);
          return onBands;
        }, []);
        let bands:any = [];
        hvacOnIndicies.forEach((onIndex: number, index: number, arr: any[]) => {
            if (!arr[index - 1] || arr[index - 1] !== onIndex - 1) {
              // bands.push({color: 'var(--onoff-band)', from: onIndex - 1, to: onIndex});

              let color = 'rgba(255, 255, 255, 0.15)';
              const befs:any = {supply: 0, return: 0};
              const afts:any = {supply: 0, return: 0};
              if (onIndex !== 0) {
                befs.supply = series[6].data[onIndex - 1];
                befs.return = series[2].data[onIndex - 1];
              }
              if (onIndex !== series[2].data.length - 1) {
                afts.supply = series[6].data[onIndex + 1];
                afts.return = series[2].data[onIndex + 1];
              }
              const onLimit = sessionData.iwg ? 0.08 : 20;
              if (befs.supply >= onLimit || befs.return >= onLimit || afts.supply >= onLimit || afts.return >= onLimit) {
                const returnTemp = series[0].data[onIndex];
                const supplyTemp = series[4].data[onIndex];
                const isCooling = supplyTemp < returnTemp;
                color = `rgba(${isCooling ? '173, 216, 230, 0.25' : '255, 192, 203, 0.25'})`
              }
              bands.push({ color, from: onIndex - 1 || onIndex, to: onIndex });
            } else {
                bands[bands.length - 1].to = onIndex;
            }
        });
        return bands;
    }
  
  const assessXAxisTickInterval = (interval:number) => {
    if (window.innerHeight >= 400 && window.innerHeight <= 880) {
      return 7.75;
    } else {
      return 2
      // window.innerHeight >= 400 && window.innerHeight <= 880 ? 4 : (interval === 86400 ? 1 : 4)
    }
  }

  const truncateName = (name: string) => {
    const lowerName = name.toLowerCase();
    switch (lowerName) {
      case 'temperature': return 'Temp';
      case 'pressure differential': return 'dP';
    }
    return name;
  }

  const genChartOptions = (
    categories: any[],
    entType: string,
    interval: number,
    initGranularUpdate: (index: number, data: any) => void,
    data: any,
    data2?: any,
    stages?: string[]
  ) => {
        let opts = {
            title: {
              text: '',
            },
            chart: {
              type: 'line',
              backgroundColor: 'transparent',
              width: '100%',
              height: '100%',
              animation: false
            },
            legend: {
              itemStyle: {
                color: 'var(--white)'
              }
            },
            credits: {
              enabled: false
            },
              tooltip: {
                shared: true // Set shared to true to enable a shared tooltip
            },
            yAxis: [
              {
                title: {
                  text: 'IAQ',
                  style: {
                    color: 'var(--crit)'
                  }
                },
                opposite: false,
                labels: {
                  style: {
                    color: 'var(--crit)',
                    fontFamily: 'Comfortaa'
                  },
                }
              },
              {
                title: {
                  text: 'CO2',
                  style: {
                    color: 'var(--cancel)'
                  }
                },
                opposite: false,
                labels: {
                  style: {
                    color: 'var(--cancel)',
                    fontFamily: 'Comfortaa'
                  },
                }
              },
              {
                title: {
                  text: 'tVOC',
                  style: {
                    color: 'var(--prim)'
                  }
                },
                opposite: true,
                labels: {
                  style: {
                    color: 'var(--prim)',
                    fontFamily: 'Comfortaa'
                  },
                },
                min: 0.001,
                max: 1
              },
              {
                title: {
                  text: 'Temp/Hum',
                  style: {
                    color: 'var(--tri)'
                  }
                },
                opposite: true,
                labels: {
                  style: {
                    color: 'var(--tri)',
                    fontFamily: 'Comfortaa',
                  },
                },
                min: 0,
                max: 100
              }
            ],
            xAxis: {
              categories,
              units: '{this.term}',
              style: {
                color: 'white',
                fontFamily: 'Comfortaa'
              },
              labels: {
                style: {
                  color: 'white',
                  fontFamily: 'Comfortaa'
                }
              },
              // minorTickInterval: assessXAxisTickInterval(interval),
              minTickInterval: assessXAxisTickInterval(interval)
            },
            series: genSeries(data, initGranularUpdate, data2 ? true : false, interval)
        }
      if (!data2) {
        const dprSeries = opts.series.find((s: any) => s.name === 'Pressure Differential');
        if (dprSeries) opts.series.splice(opts.series.indexOf(dprSeries), 1)
            return opts;
        }
        const o = { ...opts } as any;
        if (o.series.length && stages) {
          let series = [...o.series];
            let newSeries = genSeries(data2, initGranularUpdate, data2 ? true : false, interval) as any;
            series = series.map(((s:any) => {
                s.name = `${stages[0]} ${truncateName(s.name)}`
                return s;
            }));
            newSeries = newSeries.map(((s:any) => {
                s.name = `${stages[1]} ${truncateName(s.name)}`;
                s.color = setAltColor(s.name);
                return s;
            }));
          series = series.concat(newSeries);
            if (series) {
              const withDelta = addDeltaTemp(series, initGranularUpdate);
              o.yAxis = deltaYAxis(
                  series[2].data.concat(series[6].data),
                  series[3].data,
                  series[1].data.concat(series[4].data)
                );
                o.xAxis.plotBands = setBands(series)
                o.series = withDelta;
                return o;
              }
        }
        // if (o?.series?.length && stages) {
        //     let series = [ ...o.series ];
        //     let newSeries = genSeries(data2, initGranularUpdate) as any;
        //     series = series.map((s: any) => s.name = `${stages[0]} ${s.name}`);
        //     newSeries = newSeries.map((s: any) => s.name = `${stages[1]} ${s.name}`);
        //     o.series = series.concat(newSeries);
        //     return o;
        // }
        // return false;


    }

    return [
        trendData,
        setTrendData,
        getDeviceTrends,
        genChartOptions
    ]

}
