import { useEffect, useRef, useState } from "react";
import moment from 'moment';

import Trend from "../chart/Trend";
import { useTrend } from "../../hooks/useTrend";
import { useRest } from "../../hooks/useRest";
import { imgUrl } from "../../templates/consts";

import maint1 from '../../assets/images/maint_1.png';
import maint2 from '../../assets/images/maint_2.png';
import maint3 from '../../assets/images/maint_3.png';
import maint4 from '../../assets/images/maint_4.png';
import multiMaint from '../../assets/images/maint_multi.png';

interface Props {
    zone: any;
    report: any;
    trendTms?: number | null;
    pdf?: boolean;
    equip?: boolean;
    chartsLoaded?:boolean[];
    setChartsLoaded?: (chartLoad: boolean[]) => void;
}

const maintImgs = [maint1, maint2, maint3, maint4]

export const getCurrentMonthStart = () => {

    const date = new Date();
    let month = date.getMonth();
    let day = 1
    let year = date.getFullYear();
    month = month === 0 ? 11 : month;
    let dateStr = `${year}-${month}-${day.toString().length == 1 ? '0' + day : day} 08:00:00`;
    // const dateStr = '2023-11-01 08:00:00';
    const utc = Math.floor(new Date(dateStr).getTime() / 1000);
    return utc;

}

const MonthlyTrend: React.FC<Props> = ({ zone, report, trendTms, pdf, equip, chartsLoaded, setChartsLoaded }) => {

    const [trendData, setTrendData, getDeviceTrends, genChartOptions] = useTrend('zone', zone);
    const [req] = useRest();

    const [tmsRange, setTmsRange] = useState<any[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [secondaryTrendData, setSecondaryTrendData] = useState<any>(null);
    const [ rawTrends, setRawTrends ] = useState<any>(null);

    const initChartRendered = () => {
        if (chartsLoaded && setChartsLoaded) {
            setChartsLoaded([...chartsLoaded, true]);
        }
    }

    const getEquipTrends = async (dps: any, tms: number) => {
        return await Promise.all((dps.map(async (dp: any) => {
            try {
                let dpTrends = await getDeviceTrends(86400, tms, `monthly`, dp.did);
                dpTrends = dpTrends.filter((t: any) => !t.apsn);
                if (zone.sections) {
                    const dpSec = zone.sections[dp.sectionIndex];
                    let stage = dpSec.elems[0].type;
                    if (stage === 'Coil') stage = 'Supply';
                    if (stage === 'Filter') stage = 'Return';
                    return {stage: stage, data: dpTrends, apsn: dp.apsn};
                } else {
                    return {stage: 'env', data: dpTrends, apsn: dp.apsn};
                }
            } catch (getTrendsErr) {
                console.error({ getTrendsErr });
            }
        })));
    }

    const getZoneTrends = async (t: number | null) => {
        let tms: number;
        tms = t === null ? getCurrentMonthStart() : t;
        const dps = zone.datapoints;
        if (!dps || !dps.length) {
            return;
        }
        let tData;
        if (dps.length >= 1) {
            tData = await getEquipTrends(dps, tms);
        }
        return tData;
    }

    const formatLabels = (data:any) => {
        return data.map((d: any) => moment(d.tms * 1000).format('MM/DD/YYYY'));
    }

    const initScatterData = (tData:any, events: any) => {
        let scatterSeries: any = [];
        events = events.reverse();
        const tmsArr = tData.xAxis.categories.map((c: any) => Math.floor(new Date(c).getTime() / 1000));
        const getNearestTms = (evt: any) => {
            const validArr = tmsArr.filter((t: any) => t <= evt.tms);
            const absolutes = validArr.map((t: number, tIndex:number) => ({index: tIndex, abs: Math.abs(t - evt.tms)}));
            const closest = absolutes.sort((a: any, b: any) => a.abs - b.abs)[0];
            return tmsArr[closest.index];
        }
        events.forEach((e: any) => e.tms = getNearestTms(e));
        events.forEach((e: any, eIndex: number) => {
            const matches = events.filter((evt: any, evtIndex: number) => evt.tms === e.tms && evtIndex != eIndex);
            const index = tmsArr.indexOf(e.tms);
            scatterSeries.push({
                x: index, y: !pdf ? .7 : .65,
                type: 'scatter',
                marker: {
                    enabled: true,
                    symbol: pdf ? `url(${matches.length ? multiMaint : maintImgs[eIndex]})` : `url(${imgUrl}/maintNode.png)`,
                    width: !pdf ? 35 : 32.5,
                    height: !pdf ? 35 : 32.5,
                },
                lineColor: 'none',
                content: e.body,
                title: e.title,
                color: 'none',
                lineHeight: 0,
                lineWIdth: 0
            });
        });
        return scatterSeries;
    }


    const zipTrends = (datae: any[]) => {
        let opts;
        if (datae.length === 1) {
            const data = datae[0];
            const cats = data.map((d: any) => moment(d.tms * 1000).format('MM/DD/YYYY'));
            setTmsRange(data[0].data.map((d: any) => d.tms));
            opts = genChartOptions(formatLabels(data), 'datapoint', 86400, () => {}, data);
            delete opts.xAxis.plotBands;
        } else {
            const data = datae;
            let primary = data[data[0].data.length >= data[1].data.length ? 0 : 1];
            const secondary = data.find((set: any) => set.apsn !== primary.apsn);
            primary.data.splice(1, primary.data.length - secondary.data.length);
            // primary.data = primary.data.splice(0, secondary.data.length);
            const cats = primary.data.map((d: any) => moment(d.tms * 1000).format('MM/DD/YYYY'));
            setTmsRange(data[0].data.map((d: any) => d.tms));
            const returnStage = [primary, secondary].find((d: any) => d.stage === 'Return');
            const supplyStage = [primary, secondary].find((d:any) => d.stage === 'Supply');
            opts = genChartOptions(cats, 'datapoint', 86400, () => {}, returnStage.data, supplyStage.data, ['Return', 'Supply'])
            delete opts.xAxis.plotBands;
        }
        const options = opts as any;
        [1, 3, 5].forEach((x: number) => options.series[x].visible = false);
        return options;
    }

    const genEnviroTrend = (monthData: any) => {
        const data = monthData.data;
        const formattedLabels = data.map((d: any) => moment(d.tms * 1000).format('MM/DD'));
        setTmsRange(data.map((d: any) => d.tms));
        const opts = genChartOptions(formattedLabels, 'datapoint', 86400, () => { }, data);
        if (pdf) {
            opts.yAxis = opts.yAxis.map((y: any) => {
                y.gridLineColor = 'black';
                return y;
            });
            opts.xAxis.tickColor = 'black';
            opts.legend.itemStyle.color = 'var(--bg)';
            opts.xAxis.labels.style.color = 'black';
            opts.chart.height = '110%';
            opts.xAxis.minTickInterval = 7;
        }
        setTrendData(opts);
        setLoaded(true);
    }

    const formatScatter = (sctr: any, index: number, cats:string[], multi:boolean) => {
        const title = sctr[0].title;
        const content = sctr[0].content;
        const newSeries = {
            name: 'maint',
            data: sctr,
            color: 'none',
            yAxis: 0,
            showInLegend: false,
            tooltip: {
                enabled: true,
                shared: false,
                useHtml: true,
                pointFormatter() {
                    const self = this as any;
                    // const pointIndex = self.points[0].point.index;
                    // const title = report.events[index ?? 0].title;
                    // const content = report.events[index ?? 0].body;
                    return `<h2 style="color:rgb(0, 153, 191);">
                                ${title}
                            </h2>
                            <br />
                            <p style="margin-left:5px;text-align:center;">${content}</p>`
                }
            }
        } as any;
        if (index > 0) newSeries.data[0].y -= index * .2;
        return newSeries;
    }

    const initTrends = async () => {
        let monthTrends: any;
        if (rawTrends) monthTrends = rawTrends;
        else {
            monthTrends = await getZoneTrends(trendTms ?? null);
            setRawTrends(monthTrends);
        }
        if (!rawTrends) setRawTrends(monthTrends);
        if (monthTrends?.length && report && report.events) {
            if (monthTrends.length > 1) {
                const opts = zipTrends(monthTrends);
                opts.tooltip = {
                    shared: false,
                    useHtml: true,
                    style: {
                        fontSize: '15px',
                        width: '400px'
                    }
                }
                const scatter = initScatterData(opts, report.events.filter((e: any) => e.zid === zone.id));
                let eventCols:any = [];
                scatter.forEach((node: any) => {
                    eventCols.push({
                        x: node.x,
                        y: !pdf ? .65 : .65,
                        color: !pdf ? 'var(--white)' : 'var(--bg)'
                    }) 
                });
                opts.series.push({
                    type: 'column',
                    data: eventCols,
                    yAxis: 0,
                    pointWidth: 2,
                    color: 'white',
                    borderWidth: 0,
                    tooltip: {
                        enabled: false
                    },
                    lineWidth: 0,
                    showInLegend: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                });
                if (scatter?.length) {
                    if (pdf || scatter.length === 1) {
                        opts.series.push(formatScatter(scatter, 0, opts.xAxis.categories, false));
                    } else {
                        let matchedSeries = scatter.reduce((matched: any[], s: any, sIndex: number) => {
                            const hasMatch = matched.find((m: any[]) => m[0].x === s.x);
                            if (hasMatch) {
                                matched.push([s]);
                            } else {
                                const noMatch = matched.find((m: any[]) => m[0].x !== s.x);
                                if (noMatch) {
                                    const nmIndex = matched.indexOf(noMatch);
                                    noMatch.push(s);
                                    matched[nmIndex] = noMatch;
                                } else {
                                    matched.push([s]);
                                }
                            }
                            return matched;
                        }, []);
                        matchedSeries = matchedSeries.map((ms: any) => {
                            if (ms.length > 1) ms = ms.map((m: any) => {
                                m.y = .8;
                                return m;
                            });
                            return ms;
                        });
                        matchedSeries.forEach((s:any, sIndex:number) => opts.series.push(formatScatter(s, sIndex, opts.xAxis.categories, s.length > 1)));
                    }
                }
                opts.plotOptions = {
                    series: {
                        states: {
                            inactive: {
                                opacity: 1
                            }
                        }
                    }
                };
                if (pdf) {
                    opts.yAxis = opts.yAxis.map((y: any) => {
                        y.gridLineColor = 'black';
                        return y;
                    });
                    opts.xAxis.tickColor = 'black';
                    opts.legend.itemStyle.color = 'var(--bg)';
                    opts.xAxis.labels.style.color = 'black';
                    opts.series = [0, 2, 4, 6, 7, 8].map((x: number) => opts.series[x]);
                    opts.yAxis[0].min = 0;
                    opts.yAxis[0].max = 0.75;
                    opts.yAxis[0].tickInterval = 0.1;
                    opts.yAxis[0].tickPositioner = null;
                    opts.yAxis[0].gridLineColor = 'none';
                    opts.yAxis[1].title.text = null;
                    opts.xAxis.labels.style.fontSize = '1em';
                    opts.yAxis[0].title.text += ' (iwg)';
                    opts.yAxis[2].title.text = 'Temp (˚F) / Hum (%)';
                    opts.plotOptions.series.animation = false;
                    opts.chart.events = { load: initChartRendered() }
                    opts.series = opts.series.filter((s:any) => s !== undefined);
                } else {
                    opts.series = opts.series.filter((s: any) => s.name !== 'Delta Temp');
                    opts.yAxis.splice(1, 1);
                    opts.series = opts.series.map((s: any) => {
                        if (s.yAxis === 2) s.yAxis = 1;
                        return s;
                    });
                    opts.yAxis[0].min = 0;
                    opts.yAxis[0].max = 0.75;
                    opts.yAxis[0].tickInterval = 0.1;
                    opts.yAxis[0].tickPositioner = null;
                    opts.yAxis[0].gridLineColor = 'none';
                }
                opts.xAxis.minTickInterval = 7;
                if (opts) setTrendData(opts);
                setLoaded(true);
            } else {
                genEnviroTrend(monthTrends[0])
                // const formattedLabels = data.map((d: any) => moment(d.tms * 1000).format(interval === 86400 ? 'MM/DD' : 'hh:mma : M/D'));
                // setTmsRange(data.map((d: any) => d.tms));
                // const opts = genChartOptions(formattedLabels, 'datapoint', interval, initGranularUpdate, data);
                // return opts;
            }
        }
    }

    // useEffect(() => {
    //     (async () => {
    //         initTrends()
    //     })();
    // }, []);

    useEffect(() => {
        setLoaded(false);
        setTrendData(null);
        setTimeout(() => initTrends(), 1000);
    }, [window.innerWidth, window.innerHeight]);

    return (
        <div 
        className='dp-panel monthly-trend-panel' 
            style={{
                overflow: 'visible',
                // background: !pdf ? '' : 'none',
                marginTop: pdf ? (!equip ? '10px' : '50px') : '-30px',
                height: pdf ? '90%' : '90%',
                padding: pdf ? 'none' : '',
            }}>
            { !pdf && <h3 style={{marginTop: window.innerHeight <= 880 ? '15px' : '0'}}>{zone.name}</h3> }
            {
                loaded && report && report.events && <Trend componentOptions={ trendData } />
            }
            {
                loaded && report && pdf && !equip && secondaryTrendData && <Trend componentOptions={ secondaryTrendData } />
            }
        </div>
    )

}

export default MonthlyTrend;