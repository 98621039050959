import { createContext, useContext, useEffect } from "react";

import { assignAlerts, avgAlert } from '../util/alertAvg';
import { capitalize, defaultAlertThresholds, entProps, listProps, parentProps, profiles, restUrl, updateChildItem } from "../templates/consts";
import { useRest } from "./useRest";
import { AppContext } from "../context/AppContext";

interface SessionContextType {
    sessionData: {[key:string]: any};
    setSessionData: (formData:{[key:string]: any}) => void;
}

export const SessionContext = createContext<SessionContextType>({
    sessionData: {},
    setSessionData: () => {}
});

const findInHierarchy = (ent: any, entType: string, entId: string = '', sessionIds: string[]): any => {
    if (ent.organization) ent = ent.organization;
    if (entType == 'organization') return ent;
    if (Object.keys(ent).includes(entType + 's')) {
        return ent[entType + 's'].find((child: any) => child.id == entId);
    }
    const foundChildProp = entProps.map(e => e + 's').find(prop => Object.keys(ent).includes(prop))
    if (foundChildProp) {
        const child = ent[foundChildProp].find((c: any) => sessionIds.includes(c.id));
        return findInHierarchy(child, entType, entId, sessionIds)
    }
    // const childProp = entProps[entProps.indexOf(entType) + 1] + 's';
    // const child = ent[childProp].find((c: any) => sessionIds.includes(c.id));
    // return findInHierarchy(child, entType, entId, sessionIds);
}

const compileSessionIds = (session: any) => {
    return ['organization', 'building', 'floor', 'zone', 'datapoint'].reduce((tierIds: string[], tier: string) => {
        if (session.hasOwnProperty(tier)) {
            tierIds.push(typeof session[tier] == 'string' ? session[tier] : session[tier].id)
        }
        return tierIds;
    }, []);
}

const getNow = () => Math.floor(Date.now() / 1000);

const getChildProp = (tier:string|undefined) => {
    let prop;
    let title;
    let img;
    switch (tier) {
        case 'organization': 
            prop = 'buildings';
            title = 'Building';
            img = 'enterprise';
            break;
        case 'building':
            prop = 'floors';
            title = 'Floor';
            img = 'bldgType';
            break;
        case 'floor':
            prop = 'zones';
            title = 'Zone';
            img = 'tilesWhite'
            break;
        case 'zone':
            prop = 'datapoints';
            title = 'Datapoint';
            img = 'zone'
            break;
        case 'equip':
            prop = 'datapoints';
            title = 'Datapoint';
            img = 'equip';
            break;
        case 'datapoint':
            prop = '';
            title = '';
            img = 'datapoint';
    }
    return { prop, title, img } as { prop:string, title:string, img:string };
}

const utilAlerts = (alerts:any, tier:string) => {
    if (!alerts) return 0;
    return Object.keys(alerts).reduce((tierTotal, rdg) => {
        if (rdg != 'overall') tierTotal += alerts[rdg][tier];
        return tierTotal;
    }, 0);
}

export const useSession = (): [
    any,
    // (overwrite:boolean) => void,
    () => void,
    any,
    // (entType: string, entId: string | undefined, overwrite?:boolean, refresh?:boolean) => void,
    // (entType: string, entId:string) => void,
    (tier: string, data: any) => any,
    (startEnt: string) => void,
    (newSessionData: any) => void,
    (updatedEnt:any, entType:string) => void,
    (entType:string, entId:string) => void,
    (entType: string, entId: string | undefined, ent: any, session: any, triggerRefresh?:boolean) => void,
    (prop:string, active:boolean) => void
] => {

    const { appData, setAppData } = useContext(AppContext);
    const { sessionData, setSessionData } = useContext(SessionContext);

    const [request] = useRest();

    const initErr = (title:string, content:string) => {
        setAppData({ ...appData, helper: { type: 'error', title, content }});
    }

    const updateSession = (data: any) => {
        setSessionData(data);
        localStorage.setItem('breeziProSession', JSON.stringify(data));
    }

    const setErrMsg = (title: string, content: string) => setAppData({ type: 'error', title, content });
    
    const updateUtil = (tier:string, data:any) => {
        const formatName = (name:string) => {
            const prof = profiles.find(p => p.prop == data.config);
            if (name != '+|dynamic|+') return name;
            // return `${prof?.title}`;
            return data.apsn;
        }
        if (!data.meas) {
            data = avgAlert(data.organization ? data.organization : data, listProps[entProps.indexOf(tier == 'equip' ? 'zone' : tier)]);
        }
        let { prop, title, img } = getChildProp(tier);
        const util = {
            title: tier != 'datapoint' ? data.name : formatName(data.name),
            count: data[prop]?.length ?? '', 
            countStr: `${title}${data[prop]?.length == 1 ? '' : 's'}` ?? '',
            img: img,
            warn: utilAlerts(data.alerts, 'bad'),
            crit: utilAlerts(data.alerts, 'crit'),
            tier
        }
        return util;
    }


    
    // const getSessionEntity = async (entType: string, entId: string | undefined = '', overWrite:boolean=true, forceRefresh:boolean=false) => {
        // try {
            //     let bundle = getLocalBundle(entType, entId);
            //     let session: any = { ...sessionData }
        //     if (!bundle?.id || bundle.restTms < getNow() - 900 || forceRefresh) {
            //         bundle = await getRestBundle(entType, entId);
            //     }
            //     if (bundle && !sessionData.id) {
                //         if (bundle.restTms < getNow() - 900) {
                    //             session = await setInitialSession(false)
                    //         } else {
                        //             session = localStorage.getItem('breeziProSession')
                        //             session = { ...JSON.parse(session) }
                        //             delete session.toUpdate
                        //         }
                        //     }
                        //     if (entType != 'datapoint') {
                            //         bundle = avgAlert(bundle, entProps[entProps.indexOf(entType) + 1] + 's');
                            //         updateSessionEntity(entType, entId, bundle, session);
                            //         return bundle;
                            //         // updateSession({ ...sessionData, view: entType, [entType]: entId});
                            //     } else {
                                //         const zone = await getSessionEntity('zone', bundle.zid, false) as any;
                                //         if (!bundle.alerts) {
                                    //             bundle.alerts = false;
                                    //             bundle.meas = false;
                                    //             // const zone = await getSessionEntity('zone', bundle.zid, false)
                                    //             // // bundle = assignAlerts(bundle, zone.setpoints ?? false);
                                    //             // bundle.alerts = false;
                                    //             // bundle.meas = false;
                                    //         }
                                    //         bundle = assignAlerts(bundle, zone.setpoints ?? false);
                                    //         updateSessionEntity(entType, entId, bundle, session)
                                    //         return bundle
                                    //         // updateUtil(entType, bundle);
                                    //         // return assignAlerts(bundle, zone.setpoints ?? false)
                                    //     }
                                    //     // purgeChildren(entProps[entProps.indexOf(entType) + 1]);
                                    // } catch (getSessionEntityErr) {
                                        //     const err = getSessionEntityErr as any
                                        //     const title = `Error Retrieving ${capitalize(entType)} Data`
                                        //     const content = err.message
                                        //     setErrMsg(title, content)
                                        // }
                                        // }
                                        
    const purgeChildren = (startEnt:string) => {
        // const tiers = entProps.splice(entProps.indexOf(startEnt), entProps.length - 1);
        // const newSession = { ...sessionData };
        // tiers.forEach(t => {
            //     if (newSession[t]) delete newSession[t];
            // });
            // updateSession(newSession);
    }
    
    // const setInitialSession = async (overwrite:boolean=true) => {
        //     try {
            //         let ent = getLocalBundle('organization')
            //         if (ent && ent.restTms >= getNow() - 600) {
                //             ent = { ...ent, organization: avgAlert(ent.organization, 'buildings') }
                //         } else {
                    //             const rest = await getRestBundle('organization')
                    //             ent = { ...rest, organization: avgAlert(rest.organization, 'buildings') }
                    //         }
                    //         if (overwrite) {
                        //             updateSession({ ...ent, view: 'organization' });
    //         } else {
        //             return ent;
        //         }
        //     } catch (getInitialSessionErr) {
            //         const err = getInitialSessionErr as any
            //         setAppData({
                //             type: 'error',
                //             title: 'Organizaiton Data Error',
                //             content: err.message
                //         })
                //     }
                // }
    
    const addSessionEntity = () => {
        
    }
    
    const compileHierarchy = (hier: any, parentProp: string, childProp: string, remaining: any[], session:any) => {
        if (!remaining.length) return hier;
        const next = remaining.splice(1, remaining.length - 1);
        
    }
    
    const updateSessionEntity = (entType: string, entId: string | undefined, ent: any, session: any, triggerRefresh?:boolean) => {
        ent.restTms = getNow()
        const org = updateChildItem(entType, ent, session.organization);
        const newSession = { ...session, organization: org, view: entType };
        if (entType != 'organization') {
            newSession[entType] = entId;
            newSession[`session_${entType}`] = ent;
        }
        updateSession(newSession);
    }
                
    const getLocalBundle = (entType: string, entId: string = '') => {
        let loc:any = localStorage.getItem('breeziProSession');
        if (!loc) {
            return false;
        } else {
            loc = JSON.parse(loc);
            if (entType == 'init') {
                if (loc && loc.restTms >= getNow() - 900) return loc;
            } else {
                let sessionEnt = findInHierarchy(loc, entType, entId, compileSessionIds(loc));
                if (sessionEnt.restTms >= getNow() - 900) return sessionEnt;
            }
            return false;
        }
    }
    
    const getRestBundle = async (entType: string, entId: string = '') => {
        try {
            const res = await request(`${entType}/${entId}`, 'get');
            res.restTms = getNow();
        } catch (getRestBundleErr) {
            const err = getRestBundleErr as any;
            throw getRestBundleErr;
        }
    }
    
    const updateUtilData = () => {
        
    }

    const getParentEnt = (ent:any) => {
        const parentIdProps = ['parent', 'bid', 'fid', 'zid'];
        const parentIdProp = parentIdProps.find((prop:string) => ent.hasOwnProperty(prop));
        if (parentIdProp) {
            const parentId = ent[parentIdProp];
            return findInHierarchy(sessionData.organization, entProps[parentIdProps.indexOf(parentIdProp + 1)], parentId, compileSessionIds(sessionData));
        }

    }

    const getSpLineage:any = async (entType:string, ent:any, lineage:any[], targetLen:number) => {
        if (targetLen == 0) return ent.setpoints ?? defaultAlertThresholds;
        if (lineage.length == targetLen) {
            let revLineage = [ ...lineage ].reverse();
            const spIndex = revLineage.find((hierEnt:any) => hierEnt.setpoints);
            if (spIndex == -1) return ent.setpoints ?? defaultAlertThresholds;
            return revLineage[spIndex].setpoints;
        }
        const typeIndex = entProps.indexOf(entType);
        const parentProp = ['bid', 'fid', 'zid'].find((prop:string) => ent && ent.hasOwnProperty(prop));
        const parentType = entProps[typeIndex - 1];
        if (parentProp) {
            const parentId = ent[parentProp];
            const parent = await getSessionEntity(parentType, parentId);
            lineage.push(parent?.setpoints ?? false);
            return await getSpLineage(parentType, parent, lineage, targetLen);
        }
    }

    const getSessionEntity = async (entType:string, entId:string, overwrite?:boolean) => {
        try {
            let bundle = getLocalBundle(entType, entId);
            if (!bundle) {
                bundle = await getRestBundle(entType == 'init' ? 'organization' : entType, entId);
            }
            if (entType == 'init') {
                bundle.organization = avgAlert(bundle.organization);
            } else {
                const sp:any = getSpLineage(entType, bundle, [], entProps.indexOf(entType) - 1);
                bundle = entType == 'datapoint' ? assignAlerts(bundle, sp) : avgAlert(bundle, listProps[entProps.indexOf(entType) + 1], sp)
            }
            return bundle;
        } catch(getSessionEntityErr) {
            const err = getSessionEntityErr as any;
            initErr(`There was an error getting the ${capitalize(entType)} data.`, err.message);
        }
    }

    const setInitialSession = async () => {
        const org = await getSessionEntity('init', '');
    }

    const postObUpdate = (newEnt: any, entType: string) => {
        let session = { ...sessionData };
        newEnt.meas = false;
        newEnt.alerts = false;
        if (entType == 'organization') {
            const newSession = { ...session, organization: newEnt, view: 'organization', toUpdate: entType };
            updateSession(newSession);
        } else {
            const parentProp = entProps[entProps.indexOf(entType) - 1];
            const parent = session[parentProp].id ? session[parentProp] : findInHierarchy(session, parentProp, session[parentProp], compileSessionIds(session));
            parent[entType + 's'].push(newEnt);
            session.toUpdate = entType;
            updateSessionEntity(parentProp, parent.id, parent, session);
        }
    }

    const postDeleteEnt = (entType:string, entId:string) => {
        const session = { ...sessionData };
        // Not applicable for Organization (can not currently delete org), hence lack of entType === 'organization' case.
        const parentProp = entProps[entProps.indexOf(entType) - 1];
        const parent = findInHierarchy(session, parentProp, session[parentProp], compileSessionIds(session));
        const childProp = entType + 's';
        const childIndex = parent[childProp].map((child:any) => child.id).indexOf(entId);
        parent[childProp].splice(childIndex, 1);
        session.toUpdate = entType;
        updateSessionEntity(parentProp, parent.id, parent, session);
    }

    const updateUnitPrefs = (prop:string, active:boolean) => {
        updateSession({ ...sessionData, [prop]: active });
    }

    return [
        sessionData, 
        setInitialSession, 
        getSessionEntity, 
        updateUtil, 
        purgeChildren, 
        setSessionData, 
        postObUpdate, 
        postDeleteEnt, 
        updateSessionEntity,
        updateUnitPrefs
    ];
    
}