import cToF from "celsius-to-fahrenheit";

export const imgUrl = "https://breezi-pro-assets.s3-eu-west-1.amazonaws.com";
export const restUrl =
  "https://ns4nxlz33h.execute-api.us-east-1.amazonaws.com/dev";

export const capitalize = (str) => {
  const splStr = str.replace("_", " ").split(" ");
  return splStr
    .map((ss) => ss[0].toUpperCase() + ss.substring(1, ss.length))
    .join(" ");
};

export const measSet = [
  {
    prop: "days",
    img: "filterOfficial",
    title: "Days Remaining",
    units: "days",
  },
  {
    prop: "htp",
    img: "temp",
    title: "Temperature",
    units: "˚C",
  },
  {
    prop: "hhm",
    img: "hhm",
    title: "Humidity",
    units: "%",
  },
  {
    prop: "iaq",
    img: "iaq",
    title: "Air Quality",
    units: "",
  },
  {
    prop: "co2",
    img: "co2",
    title: "CO2",
    units: "ppm",
  },
  {
    prop: "voc",
    img: "nuevoVOC",
    title: "tVOC",
    units: "ppm",
  },
  // {
  //   prop: "bat",
  //   img: "bat",
  //   title: "Battery",
  //   "units": "%"
  // }
];

export const dpr = {
  prop: "dpr",
  img: "dpr",
  title: "Pressure Diff.",
  units: "Pa",
};

export const setStatus = (prop, val, transp = false) => {
  if (!val) val = false;
  if (val.hasOwnProperty("bad")) {
    if (val.crit) {
      val = "crit";
    } else if (val.bad) {
      val = "bad";
    } else {
      val = "prim";
    }
  }
  let color = "";
  if (prop != "all" || !val) {
    if (!val) {
      color = "breezi-blue";
    } else {
      color = val == "bad" ? "cancel" : val == "good" ? "prim" : val;
    }
  } else {
    color = val == "bad" ? "cancel" : val == "good" ? "prim" : val;
  }
  if (transp) color += "-transparent";
  return `var(--${color})`;
};

export const secStatus = (prop, val = false) => {
  if (!val) return "var(--breezi-recessive)";
};

export const defaultAlertThresholds = {
  htp: [
    [-25, 5],
    [6, 13],
    [14, 26],
    [27, 31],
    [32, 45],
  ],
  hhm: [
    [0, 15],
    [16, 30],
    [31, 70],
    [71, 85],
    [85, 100],
  ],
  iaq: [
    [0, 150],
    [151, 250],
    [251, 500],
  ],
  co2: [
    [0, 1000],
    [1001, 1500],
    [1501, 3000],
  ],
  voc: [
    [0, .32],
    [.33, .55],
    [.56, 1.00],
  ],
  dpr: [
    [0, 44],
    [45, 75],
    [76, 110],
  ],
  bat: [
    [26, 100],
    [11, 25],
    [0, 10],
  ],
  days: [
    [0, 10],
    [11, 25],
    [26, 180],
  ],
  coilFlow: [
    [0, 70],
    [71, 90],
    [91, 200],
  ],
  dpr: [
    [0, 70],
    [71, 101],
    [102, 120],
  ],
};

export const threshMap = {
  htp: ["crit", "bad", "good", "bad2", "crit2"],
  hhm: ["crit", "bad", "good", "bad2", "crit2"],
  iaq: ["good", "bad", "crit"],
  co2: ["good", "bad", "crit"],
  voc: ["good", "bad", "crit"],
  dpr: ["good", "bad", "crit"],
  bat: ["good", "bad", "crit"],
  days: ["crit", "bad", "good"],
  coilFlow: ["good", "bad", "crit"],
  dpr: ["good", "bad", "crit"],
};

export const cleanAvg = () => ({
  htp: 0,
  hhm: 0,
  iaq: 0,
  co2: 0,
  voc: 0,
  dpr: 0,
  bat: 0,
  days: 0,
});

export const listProps = ["buildings", "floors", "zones", "datapoints"];
export const parentProps = [false, "bid", "fid", "zid"];
export const entProps = [
  "organization",
  "building",
  "floor",
  "zone",
  "datapoint",
];

export const cleanAlerts = () => ({
  htp: {
    bad: 0,
    crit: 0,
  },
  hhm: {
    bad: 0,
    crit: 0,
  },
  iaq: {
    bad: 0,
    crit: 0,
  },
  co2: {
    bad: 0,
    crit: 0,
  },
  voc: {
    bad: 0,
    crit: 0,
  },
  dpr: {
    bad: 0,
    crit: 0,
  },
  bat: {
    bad: 0,
    crit: 0,
  },
  days: {
    bad: 0,
    crit: 0,
  },
});

export const profiles = [
  {
    prop: "filter",
    title: "Filter",
    img: "filterOfficial",
  },
  {
    prop: "iaq",
    title: "IAQ",
    img: "iaq",
  },
  {
    prop: "env",
    title: "Environment",
    img: "temp",
  },
  {
    prop: "all",
    title: "AirPulse",
    img: "infinity",
  },
];

export const assessWidth = (sec) => {
  let width = 0;
  const { elems } = sec;
  if (elems.length == 1) {
    width += elems[0].width * 2;
  } else {
    width = elems.length < 4 ? elems.length * 2 : 6;
  }
  const newGrid = Array.from(Array(width), (x, y) => Array(8).fill({}));
  return newGrid;
};

export const BreeziProVersionNumber = "1.12.8";

export const setValByUnitsPref = (prop, val, imperial, iwg) => {
  if (!val || val == "-" || (!imperial && !iwg)) return val;
  if (imperial && prop == "hhm") {
    return parseInt(cToF(val));
  } else if (iwg && prop == "dpr") {
    return parseFloat((val / 249.08891).toFixed(3));
  } else {
    return val;
  }
};

export const setUnitsByPref = (val, imperial, iwg, units) => {
  if (val == "hhm" && imperial) {
    return "˚C";
  } else if (val == "hhm" && iwg) {
    return "iwg";
  } else {
    return units;
  }
};

export const updateChildItem = (entType, updatedEnt, orgItem) => {
  try {
    if (orgItem && updatedEnt && orgItem.id === updatedEnt.id) {
      return {
        ...orgItem,
        ...updatedEnt,
      };
    }
    if (
      Array.isArray(orgItem) &&
      orgItem.length &&
      Object.keys(orgItem[0]).some((prop) => entProps.includes(prop))
    ) {
      for (let i = 0; i < orgItem.length; i++) {
        const updatedChild = updateChildItem(entType, updatedEnt, orgItem[i]);
        if (updatedChild) {
          orgItem[i] = updatedChild;
          return orgItem;
        }
      }
    }
    if (typeof orgItem === "object" && orgItem !== null) {
      for (const key in orgItem) {
        const updatedChild = updateChildItem(entType, updatedEnt, orgItem[key]);
        if (updatedChild) {
          orgItem[key] = updatedChild;
          return orgItem;
        }
      }
    }
  } catch (updateChildItemErr) {
    console.error({
      updateChildItemErr,
    });
  }
  return null;
};

export const findInHierarchy = (
  ent,
  entType,
  entId = "",
  parentType,
  sessionIds
) => {
  if (ent.organization) ent = ent.organization;
  if (entType == "organization") return ent;
  if (Object.keys(ent).includes(entType + "s")) {
    if (entType != "zone") {
      return ent[entType + "s"].find((child) => child.id == entId);
    } else {
      if (parentType != "building")
        return ent[entType + "s"].find((child) => child.id == entId);
    }
  }
  let foundChildProp = entProps[entProps.indexOf(parentType) + 1];
  parentType = foundChildProp;
  foundChildProp += "s";
  if (foundChildProp) {
    const child = ent[foundChildProp].find((c) => sessionIds.includes(c.id));
    return findInHierarchy(child, entType, entId, parentType, sessionIds);
  }
};

export const compileSessionIds = (session) => {
  return ["organization", "building", "floor", "zone", "datapoint"].reduce(
    (tierIds, tier) => {
      if (session.hasOwnProperty(tier)) {
        tierIds.push(
          typeof session[tier] == "string" ? session[tier] : session[tier].id
        );
      }
      return tierIds;
    },
    []
  );
};
