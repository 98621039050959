import { useSession } from "../../hooks/useSessionDev";
import cToF from 'celsius-to-fahrenheit'
import { defaultAlertThresholds, imgUrl } from "../../templates/consts";

interface Props {
    rdg: string;
    val: any;
    units: string;
    img: string;
    screenHeight: number;
}

const MonthlyEnviroGauge: React.FC<Props> = ({ rdg, val, units, img, screenHeight }) => {

    const [sessionData] = useSession();

    const formatHtp = (val: any) => {
        let formattedRdg = 'voc' ? parseFloat(val) : parseInt(val);
        if (rdg !== 'htp') return formattedRdg;
        return sessionData.imperial ? Math.round(cToF(formattedRdg)) : formattedRdg;
    }

    const assessAlertStatus = () => {
        let rdgAlert = '';
        const thresholds = defaultAlertThresholds as any;
        const thresh = thresholds[rdg];
        const v = rdg === 'voc' ? parseFloat(val.avg) : parseInt(val.avg);
        const foundThresh = thresh.find((t: [number, number]) => v >= t[0] && v <= t[1]);
        const threshIndex = thresh.indexOf(foundThresh);
        if (['htp', 'hhm'].includes(rdg)) {
            if ([0, 4].includes(threshIndex)) {
                return 'crit'
            } else if ([1, 3].includes(threshIndex)) {
                return 'cancel';
            } else {
                return 'prim';
            }
        } else {
            if (threshIndex === 0) return 'prim';
            if (thresh === 1) return 'bad';
            return 'crit';
        }
    }

    const calcAvgLeft = () => {
        if (val) {
            const hi = parseInt(val.hi);
            const lo = parseInt(val.lo);
            const avg = parseInt(val.avg);
            const diff = hi - lo;
            const offsetLo = avg - lo;
            let percOffset = Math.round(offsetLo / diff * 100);
            if (percOffset < 10) percOffset = 55;
            return `calc(${percOffset}%)`;
        } else {
            return 0;
        }
    }

    return (
        <div className='monthly-enviro-gauge'>
            <div className='enviro-hi-lo enviro-lo' style={{flexDirection: units === 'ppm' ? 'column': 'row', zIndex: 3}}>
                <h2>{ val?.lo ? formatHtp(val.lo) : '-'}</h2>
                <small>{units}</small>
            </div>
            <div className='enviro-span'>
                <div
                    className={`enviro-avg ${assessAlertStatus()}-enviro-avg`}
                    style={{left: calcAvgLeft()}}>
                    <div className='enviro-avg-head' style={{width: screenHeight >= 400 && screenHeight <= 800 ? '100%' : ''}}>
                        {
                            rdg !== 'voc' && <img src={`${imgUrl}/${img}.png`} />
                        }
                        {
                            rdg === 'voc' && <img src={`${imgUrl}/nuevoVOC.png`} />
                        }
                        {
                            screenHeight >= 400 && screenHeight <= 800 && <h2 style={{margin: 0, marginTop: '2px'}}>{formatHtp(val.avg)}</h2>
                        }
                    </div>
                    {
                        (screenHeight < 400 || screenHeight > 800) &&
                        <h2>{val?.avg ? formatHtp(val.avg) : '-'}</h2>
                    }

                </div>
            </div>
            <div className='enviro-hi-lo enviro-hi' style={{flexDirection: units === 'ppm' ? 'column': 'row', zIndex: 3}}>
                <h2>{val?.hi ? formatHtp(val['hi']) : '-'}</h2>
                <small>{units}</small>
            </div>
        </div>
    )
}

export default MonthlyEnviroGauge;